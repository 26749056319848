'<template>
	<div class="aboutContainer">
		<div class="banner"></div>
		<div class="content_container">
			<div class="font1">公司动态</div>
			<div class="font2"><span class="font2-text">COMPANY NEWS</span><span class="splitLine"></span></div>
			<div class="newsList" group="1">
				<div class="newsItem" v-for="item in articalList" :key="item.id">
					<div class="newsdate">{{formartDate2(item.createtime)}}<span>/ {{formartDate(item.createtime)}}</span></div>
					<div class="newsBody">
						<div class="imgContainer">
							<img :src="axios.defaults.baseURL+'/files/UploadFiles/'+item.aTitlepic" />
						</div>
						<div class="newsTitle">{{item.aTitle}}</div>
						<router-link :to="{path:'/newsDetail',query: {articleId:item.id}}"><span class="more">更多&gt;</span></router-link>
						<div class="newsText">{{item.aSummary}}</div>
					</div>
				</div>
				<div class="pageContainer">
					<paginate :page-count="Math.ceil(pageSplitInfo.total/pageSplitInfo.pageSize)" :page-range="2" :margin-pages="2"  :click-handler="clickCallback" :prev-text="'上一页'" :next-text="'下一页'" :container-class="'pagination'" :page-class="'page-item'">
					</paginate>
				</div>
			</div>
			<div style="clear:both;"></div>
		</div>
	</div>
</template>

<script>
	import Paginate from 'vuejs-paginate'
	import {
		getArticles
	} from '@/api/request'
	import {
		Base64
	} from 'js-base64'
	export default {
		name: 'news',
		components: {
			Paginate
		},
		data() {
			return {
				articalList: [],
				currentContent: "",
				pageSplitInfo: {
					currentPage: 1,
					pageSize: 5,
					sortName:"CREATETIME",
					sortString:"desc",
					total: 200,
					topicCode: "company-news"
				}
			}
		},
		computed: {

		},
		mounted() {

		},
		created() {
			this.feathData()
		},
		methods: {
			clickCallback(pageNum) {
				this.pageSplitInfo.currentPage = pageNum;
				this.feathData();
			},
			formartDate(dateStr){
				let datetime = new Date(dateStr);
				return datetime.getFullYear();
			},
			formartDate2(dateStr){
				let datetime = new Date(dateStr);
				let month = datetime.getMonth()+1;
				month = month<10?"0"+month:month;
				let date = datetime.getDate();
				date = date<10?"0"+date:date;
				return month+"-"+date;
			},
			feathData(){
				getArticles(this.pageSplitInfo).then(resp=>{
					if(resp.data.code=="200"){
						let respData = resp.data.result;
						this.articalList = respData.data;
						this.pageSplitInfo.total = respData.total;
					}
				})
			},
			linkToDetail(item){
				console.log(item);
			}
		}
	}
</script>

<style>
	.aboutContainer {
		min-height: 800px;
		background-color: #175068;
	}

	.banner {
		width: 100%;
		height: 10rem;
		background: url(../../public/images/introductionbanner.png) no-repeat;
		background-size: 100% 100%;
	}

	.newsPart {
		width: 100%;

		background-size: 110% auto;
		max-height: 74.52rem;


	}

	.newsPart .container {
		width: 48rem;
		height: 100%;
		margin: 0 auto;
		padding-top: 3.2rem;
		position: relative;
	}

	.newsPart .tabBtn {
		width: 12rem;
		height: 2.24rem;
		border: 1px solid #0e7685;
		margin: 0 auto;
	}

	.newsPart .tabBtn li {
		float: left;
		width: 50%;
		height: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
		color: #FFFFFF;
		font-size: 0.7rem;
		cursor: pointer;
	}

	.newsPart .tabBtn li[active=true] {
		background-color: #0e7685;
	}

	.newsList {
		/* height: 60rem; */
		min-height: 45rem;
		width: 100%;
		padding-top: 1.2rem;
	}

	.newsItem {
		width: 100%;
		height: 12rem;
		position: relative;
		padding-left: 17.2rem;
		margin-bottom: 1rem;
	}

	.newsdate {
		width: 9.6rem;
		height: 4.4rem;
		background: url(../../public/images/border.png) no-repeat;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		line-height: 4.4rem;
		text-align: center;
		color: #FFFFFF;
		font-size: 1.8rem;
		font-weight: 100;
	}

	.newsdate span {
		display: block;
		position: absolute;
		right: -2.5rem;
		top: 50%;
		transform: translateY(-50%);
		font-size: 1rem;
	}

	.newsBody {
		width: 100%;
		height: 100%;
		border-bottom: 2px solid #36667b;
		padding-top: 3.6rem;
	}

	.newsItem:last-child .newsBody {
		border-bottom: none !important;
	}

	.newsBody .imgContainer {
		width: 8rem;
		height: 8rem;
		overflow: hidden;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

	.newsBody .imgContainer img {
		height: 100%;
	}

	.newsBody .newsTitle {
		width: 100%;
		padding-left: 9.2rem;
		margin-bottom: 1.2rem;
		color: #FFFFFF;
		font-size: 0.8rem;
	}

	.newsBody .newsText {
		width: 100%;
		padding-left: 9.2rem;
		font-size: 0.6rem;
		color: #FFFFFF;
	}

	.newsBody .more {
		display: block;
		position: absolute;
		right: 0;
		font-size: 0.8rem;
		top: 3.6rem;
		color: #717b7c;
		cursor: pointer;
		transition: all 0.5s;

	}
	.pageContainer{
		width:100%;
		position: relative;
	}
	.pagination {
		display: block;
		position: absolute;
		left:50%;
		transform:translateX(-50%) ;

	}

	.pagination li {
		list-style: none;
		min-width: 1.5rem;
		height: 1.5rem;
		float: left;
		text-align: center;
		margin-right: 0.2rem;
		background-color: #00727A;
		color: #FFFFFF;
		border: 1px solid #FFFFFF;
	}

	.pagination .active {
		color: #FFFFFF;
		background-color: #1B98A3;
	}

	.pagination .disabled {
		color: #999999;
		border-color: #999999;
		
	}

	.pagination a {
		font-size: 0.6rem;
		line-height: 1.5rem;
		outline: none;
		display: block;
		width: 100%;
		height: 100%;
		-webkit-user-select:none;
		-moz-user-select:none;
		-ms-user-select:none;
		user-select:none;
	}

	.pagination li:first-child a,
	.pagination li:last-child a {

		padding-left: 1rem;
		padding-right: 1rem;
	}
</style>
